import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)

import 'bootstrap/dist/css/bootstrap.min.css'

import '@/assets/css/custom.min.css';
import '@/assets/css/app.css';

createApp(App).component('font-awesome-icon', FontAwesomeIcon ).use(router).use(store).mount('#app');
